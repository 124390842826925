import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import FormContact from '../components/FormContact'
import Content from '../components/Content'
//import GoogleMap from '../components/GoogleMap'
import Layout from '../components/Layout'
import './ContactPage.css'

// Export Template for use in CMS preview
export const ContactPageTemplate = ({
  body,
  title,
  subtitle
}) => (
  <main className="main-body">
    <section className="section">
      <div className="container">
        <PageHeader
          title={title}
          subtitle={subtitle}
        />
      </div>
    </section>
    <section className="section Contact--Section1">
      <div className="container Contact--Section1--Container">
        <div>
          <Content source={body} />
          <div className="Contact--Details">
        
          
          </div>
        </div>

        <div>
          <FormContact name="Contact Page Form" />
        </div>
      </div>
    </section>


  </main>
)

const ContactPage = ({ data: { page } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ContactPageTemplate {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ContactPage

export const pageQuery = graphql`
  query ContactPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        template
        subtitle
      }
    }
  }
`
